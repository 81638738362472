export const beforeAfter = [
  {
    id: 1,
    ServiceId: "12",
    description: "Short description of what was done",
    photos: [
      "brickFix_1.jpg",
      "brickFix_2.jpg"
    ]
  },
  {
    id: 2,
    ServiceId: "6",
    description: "Tree felling",
    photos: [
      "bushTrim_1.jpg",
      "bushTrim_2.jpg",
      "bushTrim_3.jpg"
    ]
  },
  {
    id: 3,
    ServiceId: "6",
    description: "Tree felling",
    photos: [
      "bushTrimming_1.jpg",
      "bushTrimming_2.jpg",
      "bushTrimming_3.jpg"
    ]
  },
  {
    id: 4,
    ServiceId: "1",
    description: "Wooden structure painting",
    photos: [
      "colorDeck_1.jpg",
      "colorDeck_2.jpg",
      "colorDeck_3.jpg",
      "colorDeck_4.jpg",
      "colorDeck_5.jpg",
      "colorDeck_6.jpg",
      "colorDeck_7.jpg",
    ]
  },
  {
    id: 5,
    ServiceId: "1",
    description: "Wooden structure painting",
    photos: [
      "colorFence_1.jpg",
      "colorFence_2.jpg",
      "colorFence_3.jpg",
      "colorFence_4.jpg",
      "colorFence_5.jpg"
    ]
  },
  {
    id: 6,
    ServiceId: "1",
    description: "Wooden structure painting",
    photos: [
      "colorWood_1.jpg",
      "colorWood_2.jpg",
    ]
  },
  {
    id: 7,
    ServiceId: "8",
    description: "Fence building",
    photos: [
      "fenseNew_1.jpg",
      "fenseNew_2.jpg",
    ]
  },
  {
    id: 8,
    ServiceId: "12",
    description: "Deck fixing",
    photos: [
      "fixDeck_1.jpg",
      "fixDeck_2.jpg",
      "fixDeck_3.jpg",
    ]
  },
  {
    id: 9,
    ServiceId: "12",
    description: "Floor fixing",
    photos: [
      "floorFix_1.jpg",
      "floorFix_2.jpg"
    ]
  },
  {
    id: 10,
    ServiceId: "10",
    description: "Grass cutting",
    photos: [
      "grass1_1.jpg",
      "grass1_2.jpg"
    ]
  },
  {
    id: 11,
    ServiceId: "10",
    description: "Grass cutting",
    photos: [
      "grass2_1.jpg",
      "grass2_2.jpg"
    ]
  },
  {
    id: 12,
    ServiceId: "10",
    description: "Grass cutting",
    photos: [
      "grassCut_1.jpg",
      "grassCut_2.jpg"
    ]
  },
  {
    id: 13,
    ServiceId: "10",
    description: "Grass cutting",
    photos: [
      "grassFrontCut_1.jpg",
      "grassFrontCut_2.jpg"
    ]
  },
  {
    id: 14,
    ServiceId: "10",
    description: "Grass cutting",
    photos: [
      "grassLong_1.jpg",
      "grassLong_2.jpg"
    ]
  },
  {
    id: 15,
    ServiceId: "7",
    description: "Guttering",
    photos: [
      "gutter_1.jpg",
      "gutter_2.jpg"
    ]
  },
  {
    id: 16,
    ServiceId: "1",
    description: "Wall painting",
    photos: [
      "paint_1.jpg",
      "paint_2.jpg",
      "paint_3.jpg"
    ]
  },
  {
    id: 17,
    ServiceId: "5",
    description: "Trash removal",
    photos: [
      "trash_1.jpg",
      "trash_2.jpg"
    ]
  },
  {
    id: 18,
    ServiceId: "10",
    description: "Grass and bush cutting",
    photos: [
      "yard_1.jpg",
      "yard_2.jpg"
    ]
  }
];