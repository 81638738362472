import React from "react";
// import * as styles from './Services.scss';
import './Service.scss';
import { url } from "inspector";

import grassManIcon from "../../assets/services/grassman.svg";
import bushManIcon from "../../assets/services/bush.svg";
import paintingIcon from "../../assets/services/painting.svg";
import plumbingIcon from '../../assets/services/plumbing.svg';
import cleaningIcon from "../../assets/services/window.svg";
import fencingIcon from "../../assets/services/fencing.svg";
import decoratingIcon from '../../assets/services/decorating.svg';
import locksmithIcon from "../../assets/services/locksmith.svg";
import gutteringIcon from "../../assets/services/guttering.svg";
import demolitionIcon from '../../assets/services/demolition.svg';
import carpetingIcon from '../../assets/services/carpeter.svg';
import clearanceIcon from '../../assets/services/clearance.svg';

interface Props {
  id: string;
  title: string;
  description: string;
  iconUrl: string;
  photoUrl: string;

  titleVisible?: boolean;
  width?: number;
  height?: number;
}

interface StyleProps {
  width?: string;
  height?: string;
}

export default class Service extends React.Component<Props> {
  render() {
    let icon = '';

    switch (this.props.id) {
      case "1":
        icon = paintingIcon;
        break;
      case "2":
        icon = decoratingIcon;
        break;
      case "3":
        icon = cleaningIcon;
        break;
      case "4":
        icon = carpetingIcon;
        break;
      case "5":
        icon = clearanceIcon;
        break;
      case "6":
        icon = bushManIcon;
        break;
      case "7":
        icon = gutteringIcon;
        break;
      case "8":
        icon = fencingIcon;
        break;
      case "9":
        icon = plumbingIcon;
        break;
      case "10":
        icon = grassManIcon;
        break;
      case "11":
        icon = locksmithIcon;
        break;
      case "12":
        icon = demolitionIcon;
        break;

      default:
        break;
    }
    
    let newStyle = {} as StyleProps;
    if (this.props.width) newStyle.width = `${this.props.width}px` ;
    if (this.props.height) newStyle.height = `${this.props.height}px`;

    return (
      <div className="Service_container" style={newStyle}>
        <div className="Service_image" style={{ backgroundImage: `url(${icon})` }} />
        {this.props.titleVisible && <h2 className="Service_title">{this.props.title}</h2>}
      </div>
    )
  }
}