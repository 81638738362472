import React from "react";
import Service from "./Service";
import './Home.scss';
import { services } from '../../store/services';
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <div className="mainContainer">
      {services.map((item, index) => <Link to={"/beforeAfter"} key={item.id}><Service {...item} titleVisible key={index} /></Link> )}
    </div>
  )
}