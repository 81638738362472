// import React from 'react';
// import logo from './logo.svg';
import './App.scss';

// const App: React.FC = () => {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
// export default App;




import * as React from 'react';
import { BrowserRouter as Router, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Home } from './routes/Home';
// import UnderMaintenance from './routes/UnderMaintenance/UnderMaintenance';
import Contacts from './components/Contacts';
import BeforeAfter from './routes/BeforeAfter';
import { Footer } from './components/Footer';
import Header from './components/Header';



type TParams = { id: string };

function Product({ match }: RouteComponentProps<TParams>) {
  return <h2>This is a page for product with ID: {match.params.id} </h2>;
}

function UnknownLink() {
  return <h2>Unknown link</h2>;
}



const initialState = {
  isMobile: false
}

type State = Readonly<typeof initialState>

export default class AppRouter extends React.Component<object, State> {
  readonly state: State = initialState;
  
  componentDidMount() {
      try {
        document.createEvent("TouchEvent");
        this.setState({ isMobile: true });
      }
      catch (e){ 
        this.setState({ isMobile: false });
      }
  }

  render() {
    return (
      <Router>
        <div>
          {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/products/1">First Product</Link>
              </li>
              <li>
                <Link to="/products/2">Second Product</Link>
              </li>
            </ul>
          </nav> */}
  
          <Header />
  
          <Switch>
            {/* <Route path="/undermaintenance" exact component={UnderMaintenance} /> */}
            <Route path="/" exact component={Home} />
            <Route path="/contacts" exact component={Contacts} />
            <Route path="/beforeafter" exact component={BeforeAfter} />
            {/* <Route path="/products/:id" component={Product} /> */}
            <Route component={UnknownLink} />
          </Switch>
  
          <Footer isMobile={this.state.isMobile} />
        </div>
      </Router>
    )
  }
}

// export default AppRouter;


