import * as React from 'react';

import './Header.scss';
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

export default class Header extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event: Event) {
    console.log('* Add scroll event');
    // let scrollTop = event.srcElement.body.scrollTop,
    // itemTranslate = Math.min(0, scrollTop/3 - 60);

    // this.setState({
    //   transform: itemTranslate
    // });
  }

  render() {
    return (
      <header>
        <div className="logoContainer">
          <Link to="/">
            <img src={Logo} className="logo" alt="logo"/>
          </Link>
        </div>
      </header>
    )
  }
}