import React, { SyntheticEvent } from 'react';
import './BeforeAfter.scss';
import { beforeAfter } from '../../store/beforeAfter';
import { services } from '../../store/services';
import Service from "../Home/Service";

// import Gallery from 'react-photo-gallery';

// @ts-ignore
import Gallery from 'react-grid-gallery';


interface BeforeAfterDataType {
    id: number,
    ServiceId: string,
    description: string,
    photos: string[]
}

interface GalleryDataTypes {
  src: string,
  width: number,
  height: number
}

interface IState {
  viewerIsOpen: boolean,
  currentImage: number
}

export default class BeforeAfter extends React.Component<{}, IState> {
  public readonly state: Readonly<IState> = {
    viewerIsOpen: true,
    currentImage: 0
  }

  // @ts-ignore
  openLightbox = (event: SyntheticEvent, data) => {
    this.setState({
      viewerIsOpen: true,
      currentImage: 0
    })
  }


  closeLightbox = () => {
    this.setState({
      viewerIsOpen: false
    })
  }

  createGallery (photos: string[]) : GalleryDataTypes[] {
    if (photos.length < 1) {
      return [];
    }

    let newSource = new Array();
  
    photos.map((name) => {
      let item = {} as GalleryDataTypes;
      item.src = require(`./photos/${name}`);
      item.width = 1;
      item.height = 1;
      newSource.push(item);
    })

    if (newSource === undefined) {
      return [];
    } else {
      return newSource;
    }
  }

  getServiceIcon (serviceId: string) {
    const service = services.filter((service) => service.id === serviceId);
    return service[0];
  }

  render () {
    return (
      <>
        {beforeAfter.map((item, index) => (
          <div key={index} className="photoContainer">
            <Service {...this.getServiceIcon(item.ServiceId)} width={60} height={60} key={item.id} />
            <div className="serviceLabel">{item.description}</div>
            <Gallery images={item.photos.map(filename => ({
                src: require(`./photos/${filename}`),
                thumbnail: require(`./photos/${filename}`),
                thumbnailWidth: 50,
                thumbnailHeight: 50
              }))}
              thumbnailWidth={10}
            />
          </div>
        ))
        }
      </>
    )
  }
}
