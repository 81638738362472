export const services = [
  {
    id: '1',
    title: 'Painting',
    description: 'description',
    iconUrl: 'painting.svg',
    photoUrl: ''
  },
  {
    id: '2',
    title: 'Decorating',
    description: 'description',
    iconUrl: 'decorating.svg',
    photoUrl: ''
  },
  {
    id: '3',
    title: 'Cleaning',
    description: 'description',
    iconUrl: 'window.svg',
    photoUrl: ''
  },
  {
    id: '4',
    title: 'Carpet cleaning',
    description: 'description',
    iconUrl: 'carpeter.svg',
    photoUrl: ''
  },
  {
    id: '5',
    title: 'House clearance',
    description: 'description',
    iconUrl: 'clearance.svg',
    photoUrl: ''
  },
  {
    id: '6',
    title: 'Tree felling',
    description: 'description',
    iconUrl: 'bush.svg',
    photoUrl: ''
  },
  {
    id: '7',
    title: 'Guttering',
    description: 'description',
    iconUrl: 'guttering.svg',
    photoUrl: ''
  },
  {
    id: '8',
    title: 'Fencing',
    description: 'description',
    iconUrl: 'fencing.svg',
    photoUrl: ''
  },
  {
    id: '9',
    title: 'Plumbing',
    description: 'description',
    iconUrl: './plumbing.svg',
    photoUrl: ''
  },
  {
    id: '10',
    title: 'Gardening',
    description: 'description',
    iconUrl: './grassman.svg',
    photoUrl: ''
  },
  {
    id: '11',
    title: 'Locksmithing',
    description: 'description',
    iconUrl: 'locksmith.svg',
    photoUrl: ''
  },
  {
    id: '12',
    title: 'Demolition jobs',
    description: 'description',
    iconUrl: 'demolition.svg',
    photoUrl: ''
  }
];