import React from "react";
import './Footer.scss';
import fbIcon from '../../assets/fb.svg';
import whatsappIcon from '../../assets/whatsapp.svg';
import mailIcon from '../../assets/mail.svg';
import phoneIcon from '../../assets/phone.svg';

type FooterProps = {
  isMobile: boolean
}

export const Footer = ({isMobile}: FooterProps) => {
  return (
    <div className="footer">
      <div>
        <a href="https://www.facebook.com/groups/180623002884739/" target="_blank">
          <img className="socialIcon" src={fbIcon} alt="Facebook" />
        </a>
        {isMobile === true ? <a href="https://api.whatsapp.com/send?phone=447908034483">
          <img className="socialIcon" src={whatsappIcon} alt="whats app" />
        </a> : ""
        }
      </div>
      <div className="contactData">
        <img src={phoneIcon} className="smallIcons" alt="Phone" />
        07 908034483
      </div>
      <div className="contactData">
        <img src={mailIcon} className="smallIcons" alt="Mail" />
        vman.maintenance@gmail.com
      </div>
      <div className="contactData" style={{ paddingBottom: "40px" }}>&copy; 2020 vman-home-maintenance.co.uk </div>
    </div>
  )
}